import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
    Koti: "/",
    "Analytiikka-konsultointi": "/fi/analytiikka-konsultointi",
    "Tapahtuman-seuranta": "/fi/tapahtuman-seuranta"
  };
  
// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/event-tracking"
);

  const EventTracking = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title={`Tapahtuman seuranta Google Analyticsissä [Asennusopas ${currentYear}]`}
          description="Opas tapahtumien seurannan määrittämiseen modernissa Google Analyticsissä yhdessä Google Tag Managerin kanssa. Opetusohjelma kuvakaappausten ja selitysten kera sertifioidulta asiantuntijalta."
          lang="fi"
          canonical="/fi/tapahtuman-seuranta"
          image="google-analytics-event-tracking-report-hero"
          alternateLangs={alternateLangs}
          datePublished="2024-06-18T04:32:43.188Z"
          dateModified="2024-06-18T06:56:07Z"
        />
        <MainContent article>
          <ImgScreenshot
            src="event-tracking/google-analytics-event-tracking-report-hero.png"
            alt="tapahtumatietojen graafi Google Analyticsin tapahtumaraportoinnista"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Tapahtuman seuranta</H>
<p>
  Google Analyticsin tapahtumaseurannan määrittäminen on olennaista datan analysoinnin kannalta, koska sen avulla voidaan seurata käyttäjän vuorovaikutuksia. Pelkkien sivunäyttöjen, istuntojen ja vierailijoiden analysointi ei anna kontekstia verkkosivun vierailijan sitoutumisesta.
</p>{" "}
<p>
  Tämä ratkaistaan keräämällä lisätietoja mukautetuista tapahtumista, jotka tallentavat käyttäjän vuorovaikutuksia, kuten esimerkiksi linkkiklikkauksia.
</p>
<p>
  Tapahtumat edustavat käyttäjän vuorovaikutuksia tai tapahtumia.
  <br />
  Yleensä tärkeimmät vuorovaikutukset liittyvät verkkosivuston tavoitteeseen.
  <br /> Siksi määritämme mukautetun tapahtumaseurannan käyttääksemme sitä suorituskykyindikaattorina nähdäksemme, saavutetaanko tavoitteemme.
</p>
<p>
  Tapahtumat voidaan ryhmitellä <strong>koviksi konversioiksi</strong> ja <strong>pehmeiksi konversioiksi</strong> sen mukaan, miten ne liittyvät verkkosivuston kokonaisvaltaiseen tavoitteeseen.
</p>
<p>
  Koska tapahtumat ovat usein perusta konversioiden seuraamiselle verkkokauppa- tai uudelleenmarkkinointikontekstissa, niitä kutsutaan joskus konversioseurannaksi.
  <br />Erottelen konversioseurannan ja tapahtuman seurannan, koska konversio voi myös olla sivunäyttö tai <b>passiivinen</b> tapahtuma, kuten tietyn ajan viettäminen aloitussivulla.
</p>
<p>
  Havainnollistaakseni, mitä tapahtumaseuranta tarkoittaa, näytän kuinka määrittää <strong>klikkauksen seuranta</strong> lähetyspainikkeeseen. Tämän jälkeen sinun pitäisi pystyä määrittämään omia ainutlaatuisia klikkaustapahtumia mihin tahansa elementtiin - aloitetaan!
</p>
<H as="h2">Mitä on tapahtumaseuranta Google Analyticsissä?</H>
<p>
  Tapahtuma voi olla joko <strong>aktiivinen käyttäjän vuorovaikutus</strong> tai <strong>passiivinen tapahtuma</strong>. Niiden seuranta tarkoittaa tapahtumien katsomista tai laskemista ja niiden suhteuttamista käyttäjiin, vierailuihin ja muihin ulottuvuuksiin myöhempää analyysia varten.
</p>
<p>Koska voit määrittää tapahtumat haluamallasi tavalla, ne voivat olla monenlaisia.</p>
<H as="h3">Esimerkkejä</H>
<ul>
  <li>tuotteen lisääminen ostoskoriin</li>
  <li>rekisteröityminen sähköpostilistalle</li>
  <li>artikkelin lopun selaaminen</li>
  <li>videon toistaminen</li>
  <li>käyttäjän kirjautuminen</li>
  <li>kuvan klikkaaminen suurentaaksesi sitä</li>
  <li>yhteydenottolomakkeen lähettäminen</li>
  <li>ulkoisen linkin klikkaaminen</li>
  <li>artikkelin tulostaminen</li>
  <li>tiedoston lataaminen</li>
</ul>
<p>
  Kuten näet, tapahtumat liittyvät yleensä johonkin, mitä käyttäjä <em>tekee</em>, perustuen seurantaan...
</p>
<ul>
  <li>hiiren klikkaukset</li>
  <li>hiiren liikkeet</li>
  <li>vierittäminen</li>
  <li>selainnavigointi</li>
  <li>näppäimistön syötteet</li>
</ul>
<p>
  Tapahtumat voivat myös olla <em>passiivisia</em>, ilman että käyttäjä tekee mitään aktiivisesti.
  <br />
  Esimerkiksi, kun käyttäjä viettää tietyn <strong>ajan sivulla</strong> tai kun lomake palauttaa <strong>virheen</strong>. Myös kun <strong>chatbot-ikkuna</strong> avautuu keskustelemaan vierailijan kanssa, käyttäjältä ei tule mitään toimintaa.
</p>
<p>Vaikka <b>sivun katselu</b> on teknisesti myös vuorovaikutus, laajalti käytetty Universal Google Analytics ei laske sivun katselua tapahtumaksi. Uusi Google Analytics App + Web (tällä hetkellä Beta-versiossa) käyttää eri datamallia, joka seuraa sivuvierailuja myös tapahtumina.</p>
<H as="h2">Tapahtumatyyppit</H>
<p>Voimme erottaa kahdenlaiset tapahtumat:</p>
<H as="h6">Aktiiviset tapahtumat (käyttäjän vuorovaikutuksella)</H>
<ul>
  <li>hiiren klikkaukset, vierityksen seuranta ja hiiren liikkeet</li>
  <li>näytön kosketus</li>
  <li>näppäimistön syötteet</li>
</ul>
<H as="h6">Passiiviset tapahtumat</H>
<ul>
  <li>ennalta määritellyn aikarajan saavuttaminen</li>
  <li>Palvelimen vastaukset</li>
  <li>Ohjelmalliset vahvistukset</li>
  <li>Kolmannen osapuolen skriptin suoritus</li>
</ul>
<p>
  Huomaa, että seuraamalla aktiivisia ja passiivisia tapahtumia voit rekonstruoida suuren osan 
  {/* <a href="/customer-journey"> */}
  asiakaspolusta
  {/* </a> */}
  . <br />
  Tällaiset tiedot mahdollistavat johtopäätösten tekemisen käyttäjän kokemuksesta alusta loppuun. Jos löydät virheitä polulla, voit nyt ryhtyä toimiin vähentääksesi näitä negatiivisia kokemuksia.
</p>
<H as="h2">Google Analyticsin tapahtumien rakenne</H>
<p>
  Jokaisella Google Analyticsin tapahtumalla on joitakin tapahtumakenttiä tai tietoja tapahtumasta, joita voimme käyttää kuvaamaan tapahtumaa:
</p>
<ul>
  <li>
    <strong>tapahtuman kategoria</strong> - tapahtumien ryhmittelyyn ryhmiin. Esimerkiksi yhteydenottolomakkeen yhteydessä haluaisit ryhmitellä kaikki tapahtumat tapahtumakategoriaan <em>yhteydenottolomake</em>
  </li>
  <li>
    <strong>tapahtuman toimenpide</strong> - vuorovaikutuksen kuvaamiseen, esim. <em>lomakkeen lähetys</em>
  </li>
  <li>
    <strong>tapahtuman tunniste</strong> - lisätiedon lisäämiseen vuorovaikutuksesta. Esimerkiksi, jos käyttäjä lähettää <em>verkkosivustonsa osoitteen</em> lomakkeen mukana, käytät sitä erottamaan vastaanotetut lomakkeet
  </li>
  <li>
    <strong>tapahtuman arvo</strong> - (<em>valinnainen</em>) numeerisen arvon lisäämiseen tietoihin. Esimerkiksi, jos keskimäärin jokainen 25. yhteydenotto johtaa 100$ konversioon, voisimme antaa arvon <em>4</em> jokaiselle tapahtumalle.
  </li>
  <li>
    <strong>tapahtuman ei-vuorovaikutus</strong> - aktiivisen tai passiivisen käyttäjän vuorovaikutuksen erottamiseen. <br />
    Oletusarvoisesti se on asetettu <code>false</code>, mikä tarkoittaa, että tapahtuma lasketaan aktiiviseksi. <strong>Tärkeää:</strong> Aktiiviset tapahtumat vaikuttavat sivun <b><Link to="/fi/wiki-analytics/pomppausprosentti">bounce rate</Link></b>:n laskemiseen, alentamalla sitä. Joten jos kamppailet epärealistisen alhaisen bounce rate:n kanssa, on todennäköistä, että jotkin tapahtumat on virheellisesti asetettu käyttäjän vuorovaikutukseksi <code>true</code>.
  </li>
</ul>
<H as="h2">Google Analyticsin tapahtumaseurannan toteuttaminen</H>
<p>Mukautetun tapahtumaseurannan toteuttamiseen on kaksi mahdollista tapaa:</p>
<ol>
  <li>
    <strong>Toteuta se</strong> käyttämällä javascript-pätkiä kaikissa asiaankuuluvissa HTML-elementeissä yhdessä onclick-tapahtumakäsittelijän kanssa - <strong>en suosittele</strong> tätä lähestymistapaa, koska se on altis virheille eikä ole skaalautuva. Jos haluat silti tehdä tämän, löydät koodiesimerkkejä{" "}
    <strong>Googlen tapahtumaseurannan dokumentaatiosta</strong>:
  </li>
</ol>
<ul>
  <li>
    <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/events" target="_blank">
      analytics.js tapahtumadokumentaatio (perintö)
    </a>
  </li>
  <li>
    <a href="https://developers.google.com/analytics/devguides/collection/gtagjs/events" target="_blank">
      gtag.js tapahtumadokumentaatio
    </a>
  </li>
</ul>
<ol start="2">
  <li>
    <strong>Sen määrittäminen Google Tag Managerilla</strong> tai muulla <Link to="/fi/tagien-hallinta">tag management system</Link> valintasi mukaan. <br />
    Helppous ja joustavuus muutosten toteuttamisessa, sekä skaalautuvuus ja helppo integrointi kolmannen osapuolen palveluihin tekevät siitä suositun toteutustavan.
  </li>
</ol>
<p>
  Selitän, kuinka seurata tapahtumia Google Tag Managerilla, koska se on laajalti käytetty tagien hallintajärjestelmä, johon kaikilla on pääsy.
</p>
<p>
  Jos haluat oppia lisää GTM:stä, kuten kuinka se asennetaan ja kuinka seurata esimerkiksi ulkoisia linkkejä ja latauksia, lue minun <Link to="/fi/google-tag-manager-asennus">Google Tag Manager -opas</Link>.
</p>
<H as="h2">Opas tapahtumien seuraamiseen Google Tag Managerilla</H>
<p>
  Mukautetun tapahtumaseurannan määrittäminen voidaan jakaa tapahtuman <strong>laukaisimen</strong> luomiseen sekä <strong>tapahtumatagin</strong> luomiseen, joka täyttää tapahtuman ominaisuudet (<em>kategoria, toimenpide, tunniste</em>, jne.). <br />
  Tapahtuman ominaisuuksien arvot voidaan määrittää joko staattisesti tai dynaamisesti - jälkimmäinen on suositeltava tapa konfiguraation skaalaamiseksi suuremmilla sivustoilla.
</p>
<p>
  Esimerkkinä toteutamme Google Analytics -seurannan painikkeen klikkaukselle. <strong>Klikkauksien seuranta</strong> on yleisin käyttäjäkäyttäytymisen seurantamuoto, joten esimerkki on sovellettavissa moniin muihin käyttötarkoituksiin.
</p>
<p>
  Tässä vaiheessa oletan, että olet jo luonut seuranta-ID:n ja että seurannan koodi on toteutettu koko sivustolla, kun <Link to="/fi/google-analytics-asennus">asetettiin Google Analytics</Link> GTM:llä.
</p>

<H as="h3">Määritä tapahtuman laukaisin Google Tag Managerissa</H>
<ul>
  <li>
    Ensin meidän on kirjauduttava sisään <a href="https://tagmanager.google.com">Google Tag Manager -konttiimme</a> ja <strong>luotava uusi laukaisin</strong>.
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-tracking-new-trigger.png"
  alt="Aseta uusi laukaisin Google Tag Managerissa"
  className="article-img"
/>
<ul>
  <li>
    Anna laukaisimelle <strong>nimi</strong>, esimerkiksi <strong>Click - Button</strong>, ja klikkaa <strong>laukaisimen konfigurointi</strong> -kenttää valitaksesi laukaisimen tyypin. Näet luettelon laukaisintyypeistä, jotka liukuvat esiin oikealta.
  </li>
  <li>
    Valitse tyypiksi <strong>Kaikki elementit</strong> ja valitse, että <strong>laukaisin aktivoituu vain joissakin klikkauksissa</strong>.
  </li>
  <li>
    Valitse ensimmäisestä pudotusvalikosta <strong>Klikkaelementti</strong>. Jos se ei ole listalla, klikkaa <strong>Valitse sisäänrakennettu muuttuja…</strong> ja aktivoi se.
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-trigger-variable.png"
  alt="Laukaisinmuuttuja Google Tag Managerissa kuvakaappaus"
  className="article-img"
/>
<ul>
  <li>
    Valitse toisesta pudotusvalikosta <strong>vastaa CSS-valitsinta</strong>.<br />
    Selitän nyt, mitä teemme:
    <br />
    Yritämme luoda klikkauslaukaisimen tietylle HTML-elementille sivustollasi - elementille, jonka klikkauksia haluat seurata. Esimerkissäni se on <strong>painike</strong>, mutta se voi olla myös <strong>kuva</strong> tai <code>&lt;div&gt;</code>-tagi tai mikä tahansa muu HTML-tagi - joten kysymys kuuluu: Miten kerromme Google Tag Managerille (GTM), mitä elementtiä seurata?
    <br />
    Vastaus on: <strong>CSS-valitsimet</strong> - Etkö tiedä, mikä se on? Se on vain ohjelmallinen tapa määritellä HTML-elementtejä sivulla ja näytän sinulle tavan saada CSS-valitsin mille tahansa elementille muutamalla hiiren klikkauksella.
  </li>
  <li>
    Googlen Chrome-selaimessa <strong>napsauta hiiren oikealla painikkeella</strong> mitä tahansa elementtiä, jonka klikkauksia haluat seurata, ja valitse <strong>tarkastele</strong>. Chrome Developer Tools avautuu ja oletuksena HTML-elementti on valmiiksi valittuna.
  </li>
  <li>
    Napsauta sitten yksinkertaisesti <strong>hiiren oikealla painikkeella</strong> elementtiä ja valitse <strong>Kopioi</strong> &gt; <strong>Kopioi JS-polku</strong> (vältä <em>kopioi valitsin</em> - se palauttaa joskus virheellisiä valitsimia).
  </li>
</ul>
<ImgContainerFixed width="529px">
  <ImgScreenshot
    src="event-tracking/google-developer-tools-copy-css-selector-for-button-tracking.png"
    alt="Kopioi CSS-valitsin painikkeen klikkauksen seurantaan"
    className="article-img"
  />
</ImgContainerFixed>
<ul>
  <li>
    Jos liität leikepöydän sisällön tekstieditoriin, näet kopioidun jotain tällaista:
    <br />
    <br />
    <code>document.querySelector(&quot;body &gt; ul &gt; button&quot;)</code>
    <br />
    <br />
    Haluamme ottaa osan, joka on hakasulkeiden sisällä, ilman “”. Joten, tämä osa:
    <br />
    <br />
    <code>body &gt; ul &gt; button</code>
  </li>
  <li>
    <strong>Tämä on CSS-valitsin</strong>, joka meidän täytyy liittää laukaisimen konfiguroinnin viimeiseen kenttään GTM:ssä (<em>katso alla</em>).
    <br />
    <strong>Kopioi</strong> arvo ja liitä se. Klikkaa <strong>tallenna</strong>.
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-trigger-configuration.png"
  alt="Tapahtumalaukaisimen konfigurointi Google Tag Managerissa kuvakaappaus"
  className="article-img"
/>
<p>Huomaa, että voit tehdä tämän millä tahansa elementillä ja asettaa minkä tahansa tapahtumalaukaisimen GTM:ssä haluamallasi tavalla?</p>
<p>
  Emme ole vielä valmiita. Kun olemme määrittäneet laukaisimen ehdot tapahtumallemme, meidän on konfiguroitava Google Analytics -tapahtumatagi varmistaaksemme, että oikeat tiedot lähetetään Google Analytics -omaisuuteen.
</p>
<H as="h3">Luo mukautettu tapahtumatagi</H>
<p>
  Nyt kun meillä on laukaisin, meidän on yhdistettävä se varsinaiseen tapahtumatagiin. Voit ajatella laukaisimia ja tageja esimerkissämme <em>milloin</em> ja <em>mitä</em>: <br /> Laukaisin määrittelee <em>milloin</em> haluamme seurata tapahtumaa (<em>kun painikettamme klikataan</em>), ja nyt määrittelemme GA-tapahtuman, joka kuvaa <em>mitä</em> teemme: <em>Lähetämme tapahtuman GA:han, mukaan lukien kaikki oikeat arvot tapahtuman ominaisuuksille.</em>
</p>
<p>Aloitetaan.</p>
<ul>
  <li>
    Siirry Google Tag Managerissa tagien valikkoon ja <em>luo uusi tagi</em>.
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-create-new-tag-screenshot.png"
  alt="Google Tag Manager luo mukautettu tapahtumatagi"
  className="article-img"
/>
<ul>
  <li>
    Anna tagille <strong>nimi</strong> kuten <em>Button Event</em> ja klikkaa <strong>tagin konfigurointi</strong>.
  </li>
  <li>
    Oikealta puolelta liukuu esiin paneeli, jossa on luettelo mahdollisista tagityypeistä. Valitse <strong>Google Analytics - Universal Analytics</strong>.
  </li>
  <li>
    Tagin konfiguroinnissa aseta <strong>seurannan tyyppi</strong> <strong>tapahtuma</strong>. Nyt näet lisää kenttiä tapahtumatietoja varten.
  </li>
  <li>
    Määritetään staattiset arvot <strong>tapahtumakategoriaksi</strong> ja <strong>toimenpiteeksi</strong> ja tehdään <strong>tapahtumatunniste</strong> dynaamiseksi.
    <br />
    Kutsutaan <strong>kategoriaa</strong> esimerkiksi <em>click events</em>, toimenpide on <em>click</em> ja tunnisteen kohdalla klikkaa Lego-palikan symbolia sen vieressä.
  </li>
  <li>
    Haluamme, että tunnisteessa on aina teksti, joka on kirjoitettu klikattuun HTML-elementtiin, jotta voimme erottaa useita painikkeita toisistaan.
    <br />
    Klikkaamisen jälkeen Lego-palikka, paneeli liukuu esiin, jotta <strong>valitse muuttuja</strong>. Klikkaa <strong>built-ins</strong> oikeassa yläkulmassa, ellei <strong>click text</strong> ole jo muuttujien luettelossa (valitse se ja ohita seuraava vaihe).
  </li>
</ul>
<ImgScreenshot
  src="event-tracking/google-tag-manager-select-built-in-variable-for-event-label.png"
  alt="sisäänrakennettu muuttuja tapahtumatunnisteelle Google Tag Managerissa"
  className="article-img"
/>
<ul>
  <li>
    Sisäänrakennettujen muuttujien luettelossa, aktivoi <strong>click text</strong> ja GTM lisää sen muuttujakenttään aaltosulkeisiin, kuten tämä: <code>{"{{Click Text}}"}</code> - Kaksinkertaiset aaltosulkeet ovat GTM:n sisäinen tapa viitata muuttujia.
  </li>
  <li>
    Tagin konfiguroinnin <strong>arvo</strong>-kenttä voidaan täyttää arvolla, jonka tapahtuman klikkaus voi edustaa. <br />
    Esimerkissäni jätän kentän <strong>tyhjäksi</strong>, koska minulla ei ole merkityksellistä rahallista arvoa painikkeen klikkaukselle.
  </li>
  <li>
    Jos tapahtuma on <strong>vuorovaikutteinen</strong>, valitse <strong>false</strong> ei-vuorovaikutteiselle tapahtumalle. Jos se on <strong>passiivinen tapahtuma</strong>, valitse <strong>true</strong>.
  </li>
  <li>
    Valitse <strong>Google Analytics -asetukset</strong> -muuttujassa muuttuja, jonka konfiguroit, kun <a href="/fi/google-analytics-asennus">asetettiin Google Analytics</a>.
  </li>
  <li>
    Jos et ole määrittänyt <strong>asetusten muuttujaa</strong>, aseta valintamerkki <em>Ota käyttöön asetusten ohitus tässä tagissa</em> ja lisää seuranta-ID (esim. <em>UA-12345678-91</em>) sinun <Link to="/fi/google-analytics-asennus"> Google Analytics -seurantakoodi</Link>.
  </li>
  <li>
    Lopuksi, vieritä alas kohtaan <strong>laukaisimen konfigurointi</strong>, <strong>klikkaa sitä</strong> ja valitse juuri määrittämämme laukaisin: <em>Click - Button</em>
  </li>
</ul>
<p>Koko tapahtumatagin konfigurointi kerralla:</p>
<ImgContainerFixed width="371px">
  <ImgScreenshot
    src="event-tracking/google-tag-manager-event-tag-configuration-screenshot.png"
    alt="GTM tapahtumatagin konfigurointi"
    className="article-img"
/>
</ImgContainerFixed>
<ul>
  <li>
    Klikkaa nyt <strong>tallenna</strong> oikeassa yläkulmassa ja olet valmis asettamaan GA-tapahtuman.
  </li>
</ul>
<p>Hienoa! Seuraava vaihe on tarkistaa, toimiiko onclick-seuranta-asetuksemme.</p>
<H as="h2">Tapahtumien virheenkorjaus</H>
<p>
  Virheenkorjauksen aloittamiseksi siirry <strong>esikatselutilaan</strong> Google Tag Manager -yleiskatsauksesta:
</p>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-trigger-preview.png"
  alt="Google Tag Manager esikatselutila"
  className="article-img"
/>
<br />
<p>
  Tämän jälkeen vierailemme sivulla, jolla on lähetyspainike. Google Tag Managerin esikatselutilan vuoksi selaimeemme on asetettu tiettyjä <Link to="/fi/wiki-analytics/evästeet">evästeitä</Link>, jotka avaavat virheenkorjausikkunan sivun alareunaan:
</p>
<ImgScreenshot
  src="event-tracking/google-tag-manager-debugging-mode.png"
  alt="Google Tag Manager tapahtumien virheenkorjain"
  className="article-img"
/>
<br />
<p>
  Jos nyt <strong>klikkaat painiketta</strong> testataksesi tapahtumaseurantaa, sinun pitäisi nähdä <strong>klikkaus</strong> vasemmassa yhteenvedon paneelissa, ja päävirheenkorjausikkunan pitäisi näyttää, että tapahtumamme laukesi:
</p>
<ImgContainerFixed width="555px">
  <ImgScreenshot
    src="event-tracking/google-tag-manager-debugger-button-click.png"
    alt="Google Tag Manager virheenkorjain klikkauksen seuranta"
    className="article-img"
  />
</ImgContainerFixed>
<br />
<p>
  Jos klikkaat <strong>painikkeen tapahtumaa</strong>, voit tarkistaa tapahtuman mukana lähetetyt tapahtumakentät:
</p>
<ImgContainerFixed width="496px">
  <ImgScreenshot
    src="event-tracking/google-tag-manager-debugger-event-properties-for-category-action-label.png"
    alt="Google Tag Manager virheenkorjain tapahtumakentät"
    className="article-img"
  />
</ImgContainerFixed>
<br />
<p>
  Huomaa, kuinka <strong>tapahtumatunniste</strong> on täytetty automaattisesti “OK”:lla? Tämä johtuu siitä, että käytimme Google Tag Managerin sisäänrakennettua muuttujaa <strong>click text</strong>, joka aina sisältää klikattavan elementin tekstin.
</p>
<p>Tärkeämpää on, että jos vierität alaspäin, voit tarkistaa tapahtuman laukaisimen säännöt:</p>
<ImgContainerFixed width="413px">
  <ImgScreenshot
    src="event-tracking/google-tag-manager-event-trigger-rules.png"
    alt="Google Tag Manager tapahtumalaukaisimen säännöt"
    className="article-img"
  />
</ImgContainerFixed>
<br />
<p>
  <strong>VINKKI</strong>: Tarkistaaksesi, että Google Analytics -tilisi vastaanotti kaikki tiedot onnistuneesti, voimme käydä <strong>reaaliaikaisessa tapahtumaraportissa</strong> Google Analyticsissa. Jos painikkeen viimeisestä klikkauksesta on kulunut jo muutama minuutti, <strong>edellisten 30 minuutin katselu</strong> auttaa.
</p>
<ImgScreenshot
  src="event-tracking/google-analytics-event-tracking-realtime-report.png"
  alt="Google Analytics reaaliaikainen tapahtumaraportti"
  className="article-img"
/>
<H as="h3">CSS-valitsimen validointi</H>
<p>
  Jos virheenkorjain ei huomannut painikkeen klikkauksia eikä näyttänyt mitään klikkaustapahtumaa, CSS-valitsimessa on luultavasti jotain vikaa.<br />
  <strong>VINKKI:</strong> Voit avata <strong>Chrome Developer Tools</strong> -työkalut sivulla, jolla on elementti, jota haluat seurata, painamalla <strong>F12</strong> tai <strong>hiiren oikealla painikkeella</strong> &gt; <strong>tarkastele</strong> ja vierailemalla <strong>elements</strong>-välilehdellä. Jos nyt painat <strong>CTRL+F</strong> tai <strong>CMD+F</strong> Macilla etsiäksesi elementtiä, voit yksinkertaisesti <strong>liittää CSS-valitsimen hakukenttään</strong>, ja kehitystyökalut hyppäävät automaattisesti elementtiin:
</p>
<ImgContainerFixed width="578px">
  <ImgScreenshot
    src="event-tracking/google-developer-tools-find-by-css-selector.png"
    alt="Google Developer Tools etsi elementti CSS-valitsimella"
    className="article-img"
  />
</ImgContainerFixed>
<p>
  Tällä tavalla voit varmistaa, että CSS-valitsin toimii. Jos kehitystyökalut eivät hyppää mihinkään elementtiin koodissa, valitsimessa on jotain vikaa. On myös <strong>selainlaajennuksia</strong>, jotka voivat auttaa sinua saamaan oikean valitsimen.
</p>
<p>
  Virheenkorjauksen ja valitsimien korjaamisen jälkeen meidän pitäisi lopulta saada klikkauksen seuranta toimimaan ja voimme palata Google Tag Manageriin <strong>poistuaksemme esikatselutilasta</strong> ja <strong>lähettää muokkaukset</strong>.
</p>
<p>
  Onnittelut tähän asti! Olet määrittänyt tapahtumaseurannan painikkeen klikkauksille perustuen CSS-valitsimeen!
</p>
<p>
  Nyt kun asetukset toimivat, voit <Link to="/fi/google-analytics-asennus">asettaa tapahtumatavoitteet</Link> Google Analyticsissa! Google Analyticsin tavoitteet ovat hyödyllinen ominaisuus, koska voit jaotella raportteja ja nähdä, kuinka monta konversiota on tapahtunut tietylle alasegmentille.
</p>
<p>
  Asettaaksesi sen, sinun täytyy vain määrittää mukautettu Google Analytics -tavoite kategoriaksi, toimenpiteeksi ja tunnisteeksi, jotka vastaavat tapahtumaasi, ja sitten se lasketaan tapahtumatavoitteeksi.
</p>
<H as="h2">CSS-valitsimien käytön edut ja haitat seurannassa</H>
<p>
  On tärkeää mainita, että tapahtumaseurannan asettaminen CSS-valitsimien perusteella ei ole kaikkein kestävin tapa.
</p>
<ImgScreenshot
  src="event-tracking/google-tag-manager-event-trigger-preview.png"
  alt="Google Tag Manager esikatselutila"
  className="article-img"
/>
<br />
<p>
  Koko tapahtumajärjestelmä hajoaa, jos CSS-valitsin ei toimi. Se on <em>kriittinen riippuvuus</em>, joka edustaa heikkoutta tässä asennustavassa.
</p>
<p>Miksi valitsin lakkaisi toimimasta?</p>
<p>
  Mitä isompi sivusto on, sitä enemmän muutoksia tapahtuu, ja sitä useampi ihminen on mukana - mahdollisesti samanaikaisesti eri tiimeissä.
  <br /> Heti kun muutos vaikuttaa valitsimemme rakenteeseen, se lakkaa toimimasta.
</p>
<p>
  Frontend-kehittäjä saattaa hyvin tehdä muutoksia sivulle jonain päivänä tulevaisuudessa, mikä voisi aiheuttaa valitsimen rikkoutumisen. Siksi olisi kestävämpää lisätä <strong>onclick-tapahtumakäsittelijä</strong> elementtiin, joka työntää kaikki tapahtumaominaisuudet <strong><Link to="/fi/data-kerros">data kerros</Link></strong>. Tämä on suositeltava lähestymistapa tärkeille KPI-mittareille ja suurille organisaatioille, joissa muutoksia ei valvota.
</p>
<p>
  Kehittyneet web-analytiikan toteutukset, jotka integroivat tietokantoja ja kolmannen osapuolen tietolähteitä, käyttävät <Link to="/fi/data-kerros">data kerrosta tapahtumien seurantaan</Link>. Lue kyseinen artikkeli tarvittavasta tapahtumaseurantakoodista.
</p>
<p>Data kerroksen käyttö on turvallisempaa ja luotettavampaa, mutta vaatii perusteellisen toteutuksen.</p>
<p>Sen sijaan, että luottaisit CSS-valitsimiin, käytä tapahtumakuuntelijoita <a href="https://developer.mozilla.org/en-US/docs/Web/Events" target="_blank">selain tapahtumille</a> laukaisimena. GTM käyttää myös klikkaustapahtumien kuuntelijoita taustalla, kun asetat klikkauslaukaisimen, mutta kun perustat seurannan data kerrokselle, voit vapaammin valita, mitä selain tapahtumia pitäisi seurata.</p>
<p>Erityisesti yksisivuinen sovellus (SPA), jossa ei ole sivulatauksia sivunavigoinnin välillä, data kerros voidaan hyvin käyttää seuraamaan <b>virtuaalisia sivunäyttöjä</b> tapahtumina ja laskea ne normaaleiksi sivunäytöiksi Google Analyticsissa.</p>
<p>
  Pienemmillä sivustoilla data kerroksen toteutuksen vaivannäkö ei aina ole perusteltua. Tällaisissa tapauksissa tapahtumaseuranta CSS-valitsimien perusteella on kelvollinen lähestymistapa.
</p>
<p>
  CSS-valitsimien käytön <strong>etu</strong> on selkeästi <strong>helppous</strong> ja kuinka <strong>nopeasti</strong> voit alkaa kerätä tietoja. Tämä voi olla hyödyllistä myös silloin, kun data kerroksen toteutus saattaa viedä aikaa resurssien puutteen vuoksi, koska voit mahdollisesti kerätä osan tiedoista vain CSS-valitsimilla.
</p>
<p>
  Haittapuoli on, että tämä tapa on lähes <strong>altis rikkoutumaan</strong> jonain päivänä, riippuen siitä, kuinka monta muutosta toteutetaan ja kuinka monta tiimiä työskentelee sivustolla.
</p>
<p>
  Mutta jos asetat web-seurantaa omalle sivustollesi ja hallitset muutoksia, voit noudattaa tätä menetelmää. Ole vain tietoinen siitä, että sivun HTML-rakenteen muutokset voivat rikkoa CSS-valitsimen.
</p>
<p>
  Yritykset, joilla on budjetti ja korkeat laatuvaatimukset tiedolle, tulisi valita data kerros tapahtumaseurannan perustaksi.
</p>
<H as="h2">Tapahtumaraportit Google Analyticsissa</H>
<p>
  Nyt kun olemme asettaneet seurannan käyttäjän vuorovaikutuksille, meidän on varmistettava, että pysymme perillä Google Analytics -tilille lähetettyjen tapahtumien määristä.
</p>
<p>
  Paras tapa tehdä tämä on joko sisäänrakennetulla <strong>tapahtumaraportilla</strong>, <strong>mukautetulla raportilla</strong> tai Google Analyticsin <strong>kojelauta</strong>.
</p>
<p>
  <strong>Google Analyticsissa</strong> voit siirtyä kohtaan <strong>Käyttäytyminen</strong> &gt; <strong>Tapahtumat</strong> &gt; <strong>Suosituimmat tapahtumat</strong> löytääksesi seuratut tapahtumatiedot:
</p>
<ImgScreenshot
  src="event-tracking/google-analytics-event-tracking-report.png"
  alt="Google Analytics tapahtumaseurannan raportin yleiskatsaus"
  className="article-img"
/>
<br />
<p>
  Koska GA:ssa ei ole oletusarvoista <b>tapahtuman nimeä</b>, näet ensin tapahtumakategoriat. Voit myös valita ensisijaiseksi ulottuvuudeksi <strong>tapahtumatoimenpide</strong> tai <strong>tapahtumatunniste</strong> porautuaksesi syvemmälle raporttiin.
</p>
<p>
  Huomaa, että tapahtumaraportti antaa meille sarakkeen <strong>Keskim. arvo</strong>, mikä on kätevää, jos tarvitsemme klikkaustapahtumien keskimääräisen arvon. Tämä voi olla merkityksellistä web-sovellukselle, joka laskee hintoja, joissa lopputulos seurataan tapahtumana. Tällä tavalla voit helposti löytää käyttäjän laskelmien keskimääräisen arvon.
</p>
<p>
  Muut yleiset esimerkit hyödyllisistä keskivertoarvoista ovat esim. hintojen tai kokojen suodatusasetukset verkkokaupassa.
</p>
<p>
  Joten pidä mielessä, että <strong>tapahtuman arvo</strong> voi aluksi tuntua epäoleelliselta, mutta siihen voi silti liittyä arvo, joka kannattaa antaa tapahtumalle, koska se olisi hyödyllistä tarkistaa keskiarvoja laajasti.
</p>
</MainContent>
</React.Fragment>

</Layout>
);

export default EventTracking;

